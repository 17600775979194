<script setup>
import { getServerDate } from "~/utils/misc";

const props = defineProps({
  photos: Array
})

const serverDate = await getServerDate();

const banner = computed(() => {
  const now = (new Date);
  if(!props.photos || !props.photos.length) return "";

  let currentPhoto = "";
  props.photos.forEach(photo => {
    if(photo.date_from && new Date(photo.date_from) > serverDate) return;
    if(photo.date_to && new Date(photo.date_to) < serverDate) return;
    currentPhoto = photo.url;
  })

  return currentPhoto;
})

</script>

<template>
<div class="bg-gray-100 h-[520px] w-full bg-center bg-cover"
     :style="{'background-image': `url(${banner})`}">
</div>
</template>
